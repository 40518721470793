import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../common/common.css";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { response } from "../mapdata/year1";
import {
  useEnrollmentsSourceEnrollmentQuery,
  useCrmQuery,
  useFinanceDashboardQuery,
  useProgramSponsorQuery,
  useEmployabilityDetailsQuery,
  useFinancePage2Query,
  useGetStudentDistributionQuery,
  useGetEventEnrollmentQuery,
  useGetEventDashboardQuery,
  useCommunityDashboardQuery,
  useAcademicSummaryQuery,
} from "../redux/slice/apiSlice";
import { formatNumberWithCommas } from "../utils/utils";
import CategoryWisePostsGraph from "../components/Community/CategoryWisePostsGraph";

const Overview = () => {
  const [enrolmentsOverall, setEnrolmentsOverall] = useState(true);
  const [enrolmentsMinistry, setEnrolmentsMinistry] = useState(false);
  const [enrolmentsDirect, setEnrolmentsDirect] = useState(false);
  // const [yearChange, setYearChange] = useState('2023-24');
  // const [year2324, setYear2324] = useState(true);
  // const [year2223, setYear2223] = useState(false);
  // const [year2122, setYear2122] = useState(false);
  const { data: crmResponse, error: crmError } = useCrmQuery();
  const {
    data: enrollmentsSourceData,
    isLoading: enrollmentsSourceDataLoading,
    error: enrollmentsSourceEnrollmentError,
  } = useEnrollmentsSourceEnrollmentQuery();
  const {
    data: emloyabilityDetails,
    isLoading: emloyabilityDetailsLoading,
    error: emloyabilityDetailsError,
  } = useEmployabilityDetailsQuery();

  const { data: financeResponse, error: financeError } =
    useFinanceDashboardQuery();
  const { data: finance2Response, error: finance2Error } =
    useFinancePage2Query();
  const { data: response, error: communityError } =
    useCommunityDashboardQuery();
  const {
    data: getEventsEnrollment,
    isLoading: getEventsEnrollmentLoading,
    error: getEventsEnrollmentError,
  } = useGetEventEnrollmentQuery();
  const {
    data: getEventsDashboard,
    isLoading: getEventsDashboardLoading,
    error: getEventsDashboardError,
  } = useGetEventDashboardQuery();
  const { data: academicResponseData, error: academicResponseDataError } =
    useAcademicSummaryQuery();
  const academic = academicResponseData?.data;

  const finance = financeResponse?.data;
  const finance2 = finance2Response?.data;
  const community = response?.data;

  const crm = crmResponse?.data;

  const { data: programSponsorResponse, error: error } = useProgramSponsorQuery(
    {
      sponsor: null,
    }
  );
  const programSponsor = programSponsorResponse?.data;
  const portfolioCompletionData =
    emloyabilityDetails?.data?.portfolio_table || [];

  useEffect(() => {
    // Cleanup Highcharts options on unmount
    return () => {
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
          thousandsSep: ",",
        },
      });
    };
  }, []);

  const PaymentsIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="5" width="20" height="14" rx="2" />
      <line x1="2" y1="10" x2="22" y2="10" />
    </svg>
  );

  const SchoolIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 10v6M2 10l10-5 10 5-10 5z" />
      <path d="M6 12v5c3 3 9 3 12 0v-5" />
    </svg>
  );

  const BookIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
      <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
    </svg>
  );

  const WorkIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    </svg>
  );

  const { data: semesterWiseData, error: semesterWiseDataError } =
    useGetStudentDistributionQuery();

  const data = semesterWiseData?.data;
  const options = {
    title: {
      text: "Semester wise Distribution of Courses",
    },
    xAxis: {
      categories: data?.semester_student.map(
        (data) => data?.AcademicYearSemester
      ),
    },
    yAxis: {
      title: {
        text: "Number of Students",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
    },
    series: [
      {
        name: "Direct",
        data: data?.semester_student.map((data) => data?.user_count?.Direct),
        type: "column",
      },
      {
        name: "MEC",
        data: data?.semester_student.map((data) => data?.user_count?.MEC),
        type: "column",
      },
      {
        name: "MOHE",
        data: data?.semester_student.map((data) => data?.user_count?.MOHE),
        type: "column",
      },
      {
        name: "Others",
        data: data?.semester_student.map((data) => data?.user_count?.Others),
        type: "column",
      },
    ],
  };

  return (
    <div className="overviewWrapper">
      <section>
        <div className="infoWrapper">
          <div className="pageTitle">
            <h1>Overview</h1>
            <p>Institutional and operational KPIs</p>
          </div>
          {/* <div className='pageActions'>
                        <label>Year</label>
                        <select onChange={(e) => this.YearChange(e)} >
                            <option value={"2023-24"}>2023-24 (Base Year)</option>
                            <option value={"2022-23"}>2022-23</option>
                            <option value={"2021-22"}>2021-22</option>
                        </select>
                    </div> */}
        </div>
      </section>
      <section className="cardInfoWrapper">
        <ol>
          <li>
            <NavLink to="/revenue">
              <div className="topSection">
                {/* <i className="material-symbols-outlined">payments</i> */}
                <div className="icons ">
                  <PaymentsIcon />
                </div>
                <div>
                  <p>Revenue EBITA</p>
                  <h2>{finance?.gap.toMillion()}</h2>
                </div>
              </div>
              <div className="progressWrapper">
                <div className="progressInfo">
                  {/* <div><span>97%</span>Achieved</div>
                                    <div>Target : 10m</div> */}
                </div>
                {/* <div className="progress">
                                    <div className="progress-bar" style={{ width: 97% }}></div>
                                </div> */}
              </div>
              <div className="actionInfo">
                <div>
                  <p>Sources</p>
                  <span>{finance?.source_type_count.toMillion()}</span>
                </div>
                <div>
                  <p>GL Accounts</p>
                  <span>{finance?.gl_account_count.toMillion()}</span>
                </div>
                <div>
                  <p>Debit</p>
                  <span>{finance?.total_debit_amount.toMillion()}</span>
                </div>
                <div>
                  <p>Credit</p>
                  <span>{finance?.total_credit_amount.toMillion()}</span>
                </div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/enrollments">
              <div className="topSection">
                {/* <i className="material-symbols-outlined">school</i> */}
                <div className="icons2">
                  <SchoolIcon />
                </div>
                <div>
                  <p>Leads</p>
                  <h2>{formatNumberWithCommas(crm?.applicant)}</h2>
                </div>
              </div>
              <div className="progressWrapper">
                <div className="progressInfo">
                  {/* <div><span>{enrollmentsSourceData?.data?.percentage_increased}%</span>Achieved</div>
                                    <div>Target : {formatNumberWithCommas(enrollmentsSourceData?.data?.total_target ?? 5000)}</div> */}
                </div>
                {/* <div className="progress"> */}
                {/* <div className="progress-bar" style={{ width: ${enrollmentsSourceData?.data?.percentage_increased}% }}></div> */}
                {/* </div> */}
              </div>
              <div className="actionInfo">
                <div>
                  <p>Direct</p>
                  <span>
                    {programSponsor?.program_data.reduce(
                      (sum, value) => sum + value.user_count?.Direct,
                      0
                    )}
                  </span>
                </div>
                <div>
                  {/* </div> */}
                  <p>MoHERI</p>
                  <span>
                    {programSponsor?.program_data.reduce(
                      (sum, value) => sum + value.user_count?.MoHERI,
                      0
                    )}
                  </span>
                </div>
                {/* </div>) */}
                <div>
                  <p>Others</p>
                  <span>
                    {programSponsor?.program_data.reduce(
                      (sum, value) => sum + value.user_count?.Others,
                      0
                    )}
                  </span>
                </div>

                {/* {
                                    enrollmentsSourceData?.data?.pie_chart_data?.map((data) =>
                                        <div>
                                            <p>{data?.enrollment_source}</p>
                                            <span>{formatNumberWithCommas(data?.total_enrollment)}</span>
                                        </div>)
                                } */}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/programmes">
              <div className="topSection">
                {/* <i className="material-symbols-outlined">book_2</i> */}
                <div className="icons3">
                  <BookIcon />
                </div>
                <div>
                  <p>Programmes</p>
                  <h2>{crm?.program}</h2>
                </div>
              </div>
              <div className="progressWrapper">
                <div className="progressInfo">
                  {/* <div><span>85%</span>Achieved</div>
                                    <div>Target : 1000</div> */}
                </div>
                {/* <div className="progress">
                                    <div className="progress-bar" style={{ width: 85% }}></div>
                                </div> */}
              </div>
              <div className="actionInfo">
                {/* {enrollmentsSourceData?.data?.course_data?.map((source, index) => (
                                    <div className='flex' key={index}>
                                        <p>{source.program_name}</p>
                                        <span>{formatNumberWithCommas(source.total_enrollment)}</span>

                                    </div>
                                ))} */}
                <div>
                  <p>Bachelor</p>
                  <span>
                    {crm?.program_status.reduce(
                      (sum, value) => sum + value.user_count?.Bachelor,
                      0
                    )}
                  </span>
                </div>
                <div>
                  {/* </div> */}
                  <p>Diploma</p>
                  <span>
                    {crm?.program_status.reduce(
                      (sum, value) => sum + value.user_count?.Diploma,
                      0
                    )}
                  </span>
                </div>
                {/* </div>) */}
                <div>
                  <p>GFP-Fnd</p>
                  <span>
                    {crm?.program_status.reduce(
                      (sum, value) => sum + value.user_count?.GFP,
                      0
                    )}
                  </span>
                </div>
                <div>
                  <p>Masters</p>
                  <span>
                    {crm?.program_status.reduce(
                      (sum, value) => sum + value.user_count?.Master,
                      0
                    )}
                  </span>
                </div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/employability">
              <div className="topSection">
                {/* <i className="material-symbols-outlined">work</i> */}
                <div className="icons4">
                  <WorkIcon />
                </div>
                <div>
                  <p>Employability</p>
                  <h2>{emloyabilityDetails?.data?.job_couts.opportunities}</h2>
                </div>
              </div>
              <div className="progressWrapper">
                <div className="progressInfo"></div>
              </div>
              <div className="actionInfo">
                <div>
                  <p>Published</p>
                  <span style={{ paddingLeft: "15px" }}>
                    {emloyabilityDetails?.data?.total_published_job}
                  </span>
                </div>
                <div>
                  <p>UnPublished</p>
                  <span style={{ paddingLeft: "15px" }}>
                    {emloyabilityDetails?.data?.total_unpublished_job}
                  </span>
                </div>
                <div>
                  <p>InActive</p>
                  <span style={{ paddingLeft: "15px" }}>
                    {emloyabilityDetails?.data?.total_inactive_job}
                  </span>
                </div>
                <div>
                  <p>Internships</p>
                  <span style={{ paddingLeft: "15px" }}>
                    {emloyabilityDetails?.data?.total_internship}
                  </span>
                </div>
              </div>
            </NavLink>
          </li>
        </ol>
      </section>
      {/* <div className='bg-white p-2 rounded-sm'> */}
      <section className="chartWrapper">
        <div className="chartLeft">
          <div className="chartheading">
            {/* <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: 'bar',
            },
            title: {
              text: 'Transaction',
            },
            xAxis: {
              categories: finance2?.source_creadit_debit_amount?.map((data) => data?.source_type),  // User IDs as categories
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            legend: {
              reversed: true,
            },
            plotOptions: {
              series: {
                stacking: 'normal',
                dataLabels: {
                  enabled: true,
                },
              },
            },
            series: [
              {
                name: '0',
                data: finance2?.user_source_graph?.map((data) => data?.source_user_count['0'] ?? 0),  // Data for '0' source type
              },
              {
                name: '1',
                data: finance2?.user_source_graph?.map((data) => data?.source_user_count['1'] ?? 0),  // Data for '1' source type
              },
              {
                name: '3',
                data: finance2?.user_source_graph?.map((data) => data?.source_user_count['3'] ?? 0),  // Data for '3' source type
              },
              {
                name: 'other',
                data: finance2?.user_source_graph?.map((data) => data?.source_user_count[''] ?? 0),  // Data for 'other' source type
              },
            ],
          }}
        /> */}
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "bar",
                },
                title: {
                  text: "Transaction",
                },
                xAxis: {
                  categories: finance2?.source_creadit_debit_amount?.map(
                    (data) => data?.source_type
                  ), // Source types as categories
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: "",
                  },
                },
                legend: {
                  reversed: true,
                },
                plotOptions: {
                  series: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: true,
                    },
                  },
                },
                series: [
                  {
                    name: "Number of Credit",
                    data: [
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 3
                      )?.credit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 1
                      )?.credit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 0
                      )?.credit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === null
                      )?.credit_amount_count ?? 0,
                    ],
                  },
                  {
                    name: "Number of Debit",
                    data: [
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 3
                      )?.debit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 1
                      )?.debit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === 0
                      )?.debit_amount_count ?? 0,
                      finance2?.source_creadit_debit_amount?.find(
                        (data) => data?.source_type === null
                      )?.debit_amount_count ?? 0,
                    ],
                  },
                  {
                    name: "Total Debit Amount",
                    data: [
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 3
                        )?.total_debit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 1
                        )?.total_debit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 0
                        )?.total_debit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === null
                        )?.total_debit_amount ?? 0
                      ),
                    ],
                  },
                  {
                    name: "Total Credit Amount",
                    data: [
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 3
                        )?.total_credit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 1
                        )?.total_credit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === 0
                        )?.total_credit_amount ?? 0
                      ),
                      parseFloat(
                        finance2?.source_creadit_debit_amount?.find(
                          (data) => data?.source_type === null
                        )?.total_credit_amount ?? 0
                      ),
                    ],
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="w-[100%] bg-white">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      </section>

      <section className="chartWrapper">
        <div className="chartLeft">
          {/* <div className='w-[100%] h-[200px]'> */}
          {/* <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'area',
                            },
                            title: {
                                text: 'Monthly Unique Users',
                            },
                            xAxis: {
                                categories: ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024', 'April 2024', 'May 2024', 'June 2024', 'July 2024', 'August 2024', 'September 2024'], // Adjust this to match your data
                            },
                            yAxis: {
                                title: {
                                    text: 'Values',
                                },
                            },
                            series: [{
                                name: 'Users',
                                data: [500, 450, 600, 700, 650, 1300, 1200, 1500, 1400, 1100, 950, 1600] // Add your actual data here
                            }],
                            plotOptions: {
                                line: {
                                    dataLabels: {
                                        enabled: true
                                    },
                                    enableMouseTracking: true
                                }
                            }
                        }}
                    /> */}
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "area",
              },
              title: {
                text: "Monthly Unique Users",
              },
              xAxis: {
                // categories: ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024', 'April 2024', 'May 2024', 'June 2024', 'July 2024', 'August 2024', 'September 2024'],
                // categories: academic?.user_accessed.map((data) => data?.activity_month),
                categories: academic?.user_accessed
                  .slice()
                  .reverse()
                  .map((data) => data?.activity_month),
              },
              yAxis: {
                title: {
                  text: "Values",
                },
              },
              series: [
                {
                  name: "Users",
                  type: "area",
                  data: academic?.user_accessed
                    .slice()
                    .reverse()
                    .map((data) => data?.user_count),
                },
              ],
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true,
                  },
                  enableMouseTracking: true,
                },
              },
            }}
          />
        </div>
        <div className="chartRight">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "column",
              },
              credits: { enabled: false },
              title: {
                text: "Corporates Hiring",
              },
              xAxis: {
                type: "category",
                categories: emloyabilityDetails?.data?.company_target.map(
                  (data) => data?.company
                ),

                max: 3,
                tickLength: 0,
                scrollbar: {
                  enabled: true,
                  showFull: false,
                },
              },
              yAxis: {
                title: {
                  text: "Corporates Hiring",
                },
              },
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: false,
                  },
                },
              },

              series: [
                {
                  name: "Full Time",
                  color: "#3142A3",
                  data: emloyabilityDetails?.data?.company_target.map(
                    (item) => ({
                      name: item?.company,
                      y: parseInt(item?.full_time, 10) || 0, // Ensure the value is a number
                    })
                  ),
                },
                {
                  name: "Internship",
                  color: "#87A2FF",
                  data: emloyabilityDetails?.data?.company_target.map(
                    (item) => ({
                      name: item?.company,
                      y: parseInt(item?.internship, 10) || 0, // Ensure the value is a number
                    })
                  ),
                },
                // {
                //     name: 'Total User Attempted',
                //     color: "#59BFEC",
                //     data: getEventsEnrollment?.data?.top_events.map(item => ({
                //         name: item.name,
                //         y: parseInt(item.total_user_attempted, 10) || 0, // Ensure the value is a number
                //     })),
                // }
              ],
            }}
          />
        </div>
      </section>
      <section className="chartWrapper">
        <div className="chartLeft">
          <div className="chartWrapper">
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                credits: { enabled: false },
                chart: {
                  type: "pie",
                },
                accessibility: {
                  point: {
                    valueSuffix: "Events",
                  },
                },
                tooltip: {
                  pointFormat: "<b>{point.y}</b> Events",
                },
                title: {
                  text: "Event Participation",
                },
                legend: {
                  enabled: true,
                  floating: false,
                  borderWidth: 0,
                  align: "center",
                  layout: "horizontal",
                  verticalAlign: "bottom",
                  padding: 3,
                },
                plotOptions: {
                  pie: {
                    shadow: false,
                  },
                  series: {
                    allowPointSelect: true,
                    showInLegend: true,
                    dataLabels: {
                      enabled: false,
                      format: "{point.name} - {point.y}%",
                    },
                  },
                },
                series: [
                  {
                    name: "Enrollments",
                    colorByPoint: true,
                    data: getEventsDashboard?.data.event_creation.map(
                      (item) => ({
                        name: item.month_year,
                        y: parseInt(item.event_count, 10) || 0, // Ensure the value is a number
                      })
                    ),
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="chartRight">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              credits: { enabled: false },
              chart: {
                type: "column",
                scrollablePlotArea: {
                  maxWidth: 300, // Set the minimum width for scrollable plot area
                },
              },
              title: {
                text: "Monthly Event Distribution",
              },
              xAxis: {
                categories: getEventsEnrollment?.data?.top_events?.map(
                  (data) => data?.name
                ),
              },
              yAxis: {
                title: {
                  text: "Monthly Event Distribution",
                },
              },
              plotOptions: {
                column: {
                  grouping: true, // Default behavior; columns will be placed side by side
                },
              },
              series: [
                {
                  name: "Participants",
                  color: "#3142A3",
                  data: getEventsEnrollment?.data?.top_events.map((item) => ({
                    name: item.name,
                    y: parseInt(item.participants, 10) || 0, // Ensure the value is a number
                  })),
                },
                {
                  name: "Total User Attempted",
                  color: "#59BFEC",
                  data: getEventsEnrollment?.data?.top_events.map((item) => ({
                    name: item.name,
                    y: parseInt(item.total_user_attempted, 10) || 0, // Ensure the value is a number
                  })),
                },
              ],
            }}
          />
        </div>
      </section>
      <section className="chartWrapper">
        <div className="chartLeft">
          <div className="chartWrapper">
            <CategoryWisePostsGraph community={community} />
          </div>
        </div>
        <div className="chartRight">
          {/* <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                scrollablePlotArea: {
                                    maxWidth: 300, // Set the minimum width for scrollable plot area
                                }
                            },
                            title: {
                                text: 'Monthly Event Distribution'
                            },
                            xAxis: {
                                categories: getEventsEnrollment?.data?.top_events?.map((data) => data?.name)
                            },
                            yAxis: {
                                title: {
                                    text: "Monthly Event Distribution"
                                },
                            },
                            plotOptions: {
                                column: {
                                    grouping: true, // Default behavior; columns will be placed side by side
                                }
                            },
                            series: [
                                {
                                    name: 'Participants',
                                    color: "#3142A3",
                                    data: getEventsEnrollment?.data?.top_events.map(item => ({
                                        name: item.name,
                                        y: parseInt(item.participants, 10) || 0, // Ensure the value is a number
                                    })),
                                },
                                {
                                    name: 'Total User Attempted',
                                    color: "#59BFEC",
                                    data: getEventsEnrollment?.data?.top_events.map(item => ({
                                        name: item.name,
                                        y: parseInt(item.total_user_attempted, 10) || 0, // Ensure the value is a number
                                    })),
                                }
                            ],
                        }}
                    /> */}
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "bar",
              },
              title: {
                text: "Portfolio Management",
              },
              xAxis: {
                categories: portfolioCompletionData?.map(
                  (data) => data?.role || "Unknown"
                ), // Mapping roles (Alumni, Lead, etc.)
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Amount",
                },
              },
              legend: {
                reversed: true,
              },
              plotOptions: {
                series: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              series: [
                {
                  name: "Profiles Created", // "profile_created" field from the data
                  data: portfolioCompletionData?.map(
                    (data) => data?.profile_created || 0
                  ), // Fallback to 0 if undefined
                },
                {
                  name: "Total Users in Role", // "total_user_role" field from the data
                  data: portfolioCompletionData?.map(
                    (data) => data?.total_user_role || 0
                  ), // Fallback to 0 if undefined
                },
              ],
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default Overview;