import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetStudentDistributionQuery } from "../redux/slice/apiSlice";

const SemesterWiseClass = () => {

  const { data: response, error: error } = useGetStudentDistributionQuery()

  const data = response?.data;
  const options = {
    title: {
      text: "Semester wise Distribution of Courses",
    },
    xAxis: {
      categories: data?.semester_student.map((data) => data?.AcademicYearSemester),
    },
    yAxis: {
      title: {
        text: "Number of Students",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
    },
    series: [
      {
        name: "Direct",
        data: data?.semester_student.map((data) => data?.user_count?.Direct),
        type: "column",
      },
      {
        name: "MEC",
        data: data?.semester_student.map((data) => data?.user_count?.MEC),
        type: "column",
      },
      {
        name: "MOHE",
        data: data?.semester_student.map((data) => data?.user_count?.MOHE),
        type: "column",
      },
      {
        name: "Others",
        data: data?.semester_student.map((data) => data?.user_count?.Others),
        type: "column",
      },
    ],
  };


  const options3 = {
    title: {
      text: "APPLICATIONS",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.y}",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Applications",
        type: "pie",
        data: data?.applications.map((item) => ({
          name: `${item.AcademicYear} - ${item.Intake}`,
          y: item.user_count,
        })),
      },
    ],
  };
  
  

  return (
    <div className="">
      <div className="flex flex-row gap-5">
        <div className="w-[60%] bg-white">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="flex flex-col w-[40%]">

          <div className="bg-red-500 text-white px-4 py-2 rounded-md">
            Student 
          </div>
          <div className="">
            <HighchartsReact
              highcharts={Highcharts} options={options3} />
          </div>


        </div>
      </div>

      {/* <div className="mt-4">
        <HighchartsReact highcharts={Highcharts} options={{
          chart: {
            type: "pie",
          },
          title: {
            text: "Sponsorship Funding",
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: false,
              },
              showInLegend: true,
            },
          },
          series: [
            {
              name: "Students",
              colorByPoint: true,
              data: data?.sponsroship.map((data) => {
                return {
                  name: data?.SponserType,
                  y: data?.user_count,
                }
              })

            },
          ],
        }} />
      </div> */}


      <div>
      {/* <h1>Total Admissions</h1> */}
      <h3 className="text-xl font-bold mb-2">Total Admissions</h3>
        <table className="border-collapse border border-gray-400 w-full my-2 fixed_header">
          <thead>
            <tr className="bg-sky-400 text-black">
              <th className="border border-gray-400 p-2">Course Semester</th>
              <th className="border border-gray-400 p-2">Direct</th>
              <th className="border border-gray-400 p-2">MEC</th>
              <th className="border border-gray-400 p-2">MOHE</th>
              <th className="border border-gray-400 p-2">Others</th>
              <th className="border border-gray-400 p-2">Total</th>

              
            </tr>
          </thead>
          <tbody>
            {data?.semester_student.map((semester) => {
              const totalUsers = Object.values(semester?.user_count).reduce((sum, value) => sum + value, 0);
              return (
                <tr key={semester?.AcademicYearSemester}>
                  <td className="border border-gray-400 p-2">{semester?.AcademicYearSemester}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.Direct}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.MEC}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.MOHE}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.Others}</td>
                  <td className="border border-gray-400 p-2">{totalUsers}</td>
                  
                </tr>
              );
            })}
          </tbody>



        </table>
        {/* <h1>New Admissions</h1> */}
        <h3 className="text-xl font-bold mb-2">New Admissions</h3>
        <table className="border-collapse border border-gray-400 w-full my-2 fixed_header">
          <thead>
            <tr className="bg-sky-400 text-black">
              <th className="border border-gray-400 p-2">Course Semester</th>
              <th className="border border-gray-400 p-2">Direct</th>
              <th className="border border-gray-400 p-2">MEC</th>
              <th className="border border-gray-400 p-2">MOHE</th>
              <th className="border border-gray-400 p-2">Others</th>
              <th className="border border-gray-400 p-2">Total</th>

              
            </tr>
          </thead>
          <tbody>
          <tr>
                  <td className="border border-gray-400 p-2">2023 - Fall</td>
                  <td className="border border-gray-400 p-2">561</td>
                  <td className="border border-gray-400 p-2">336</td>
                  <td className="border border-gray-400 p-2">1646</td>
                  <td className="border border-gray-400 p-2">62</td>
                  <td className="border border-gray-400 p-2">2605</td>
                  
                </tr>
                <tr >
                  <td className="border border-gray-400 p-2">2024 - Spring</td>
                  <td className="border border-gray-400 p-2">325</td>
                  <td className="border border-gray-400 p-2">144</td>
                  <td className="border border-gray-400 p-2">87</td>
                  <td className="border border-gray-400 p-2">38</td>
                  <td className="border border-gray-400 p-2">594</td>
                  
                </tr>
                <tr >
                  <td className="border border-gray-400 p-2">2024 - Summer	</td>
                  <td className="border border-gray-400 p-2">12</td>
                  <td className="border border-gray-400 p-2">6</td>
                  <td className="border border-gray-400 p-2">0</td>
                  <td className="border border-gray-400 p-2">0</td>
                  <td className="border border-gray-400 p-2">18</td>
                  
                </tr>
                <tr >
                  <td className="border border-gray-400 p-2">Total</td>
                  <td className="border border-gray-400 p-2">898</td>
                  <td className="border border-gray-400 p-2">486</td>
                  <td className="border border-gray-400 p-2">1733</td>
                  <td className="border border-gray-400 p-2">100</td>
                  <td className="border border-gray-400 p-2">3217</td>
                  
                </tr>
            {/* {data?.semester_student.map((semester) => {
              const totalUsers = Object.values(semester?.user_count).reduce((sum, value) => sum + value, 0);
              return (
                <tr key={semester?.AcademicYearSemester}>
                  <td className="border border-gray-400 p-2">{semester?.AcademicYearSemester}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.Direct}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.MEC}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.MOHE}</td>
                  <td className="border border-gray-400 p-2">{semester?.user_count?.Others}</td>
                  <td className="border border-gray-400 p-2">{totalUsers}</td>
                  
                </tr>
              );
            })} */}
          </tbody>



        </table>

      </div>


    </div>
  );
};

export default SemesterWiseClass;
