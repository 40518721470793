import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React from 'react';
import { useFinanceDashboardQuery } from '../../../redux/slice/apiSlice';

import { formatNumberWithCommas } from '../../../utils/utils';
import FinancialPage2 from './Finance2';

const FinancialDashboard = () => {

    const { data: financeResponse, error: financeError } = useFinanceDashboardQuery();

    const finance = financeResponse?.data;

    let minValue = 0, maxValue = 0;
    const categories = finance?.batch_wise_credit_debit.map(data => data?.journal_batch_name !== "" ? data?.journal_batch_name : "(blank)");

    const data = finance?.batch_wise_credit_debit.map(data => {
        minValue = Math.min(minValue, data?.total_amounts ?? 0);
        maxValue = Math.max(maxValue, data?.total_amounts ?? 0);
        return data?.total_amounts;
    });





    return (
        <div className="p-4">
            {/* Card Layout */}

            <div className='flex justify-end my-2'>
                <label>Year</label>
                <select >
                    <option value={"2023-24"}>2023-24 (Base Year)</option>
                    <option >--</option>
                    {/* <option value={"2022-23"}>2022-23</option>
                    <option value={"2021-22"}>2021-22</option> */}
                </select>
            </div>
            <div className="grid grid-cols-4 gap-4 mb-6">
                <div className="bg-gray-200 p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.transaction_count??0)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Transactions</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.gl_account_count)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">GL Accounts</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.total_document_type)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Documents Types</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.total_document)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Documents</p>
                </div>
                <div className="bg-gray-200 p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.source_type_count)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Sources</p>
                </div>
                <div className="bg-[#f2d3a5] p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.total_credit_amount)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Credited</p>
                </div>
                <div className="bg-[#f2d3a5] p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.total_debit_amount)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Debited</p>
                </div>
                <div className="bg-[#f2d3a5] p-4 rounded-md text-center">
                    <h2 className="text-2xl">{formatNumberWithCommas(finance?.gap)}</h2>
                    <p className="text-[#4f8ae8] text-xl font-bold">Gap</p>
                </div>

            </div>

            {/* Journal Batch Wise Expenses Table */}
            <div className="bg-white p-2">
            <h3 className="text-xl font-semibold mb-4">Journal Batch Wise Expenses</h3>
            <div className="grid grid-cols-2 gap-6">
                <div className='bg-white p-2 rounded-sm'>
                  
                    <table className="w-full table-auto border-collapse border border-gray-400">
                        <thead>
                            <tr>
                                <th className="border p-2">Journal Batch</th>
                                <th className="border p-2">Sum of Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                finance?.batch_wise_credit_debit.map((data) => <tr><td className="border p-2">{data?.journal_batch_name != "" ? data?.journal_batch_name : "(blank)"}</td><td className="border p-2">{formatNumberWithCommas(data?.total_amounts)}</td></tr>)
                            }

                        </tbody>
                    </table>
                </div>


                <div className='bg-white p-2 rounded-sm'>
                    
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: { type: 'column' },
                            title: { text: '' },
                            xAxis: {
                                categories: categories,  // Dynamic categories from the finance array
                                title: { text: 'Journal Batch' },
                            },
                            yAxis: { min: minValue, max: maxValue, title: { text: 'Amount' } },
                            series: [{
                                name: 'Amount',
                                data: data,  // Dynamic data from the finance array
                            }],
                        }}
                    />
                </div>
            </div>
            </div>
            <FinancialPage2 />

            <div className='bg-white p-2 rounded-sm'>
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: 'column'
      },
      title: {
        text: 'Monthly Financial Overview'
      },
      xAxis: {
        categories: finance?.month_credit_debit?.map(item => item.month) || [],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Amount'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: false,
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderWidth: 1,
        borderColor: '#AAA',
        borderRadius: 3,
        shadow: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: 'Credit',
          data: finance?.month_credit_debit?.map(item => item.credit) || [],
          color: '#4CAF50'
        },
        {
          name: 'Debit',
          data: finance?.month_credit_debit?.map(item => item.debit) || [],
          color: '#F44336'
        },
        {
          name: 'EBITA',
          data: finance?.month_credit_debit?.map(item => item.ebita) || [],
          color: '#2196F3'
        }
      ]
    }}
  />
</div>

        </div>
    );
};

export default FinancialDashboard;