import React, { useCallback, useState } from 'react'
import { useCommunityDashboardQuery, useCommunityContributersQuery, useAcademicSummaryQuery,useAcademicProgramsQuery} from '../../redux/slice/apiSlice'
import CommunityOverview from '../../components/Community/CommunityOverview'
import CategoryWisePostsGraph from '../../components/Community/CategoryWisePostsGraph'
import CategoryWisePostsTable from '../../components/Community/CategoryWisePostsTable'
import AcademicUserTable from './AcademicUserTable'
import TrendingPosts from '../../components/Community/TrendingPosts'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import TrendingReels from '../../components/Community/TrendingReels'
import TopContributor from '../../components/Community/TopContributor'
import AcademicOverview from './AcademicOverview'
import AcademicProgramTable from './AcademicProgramTable'
import UserActivityTable from './UserActivityTable'
import UserEngagement from './UserEngagement'
const AcademicDashboard = () => {
    const [categoryId, setCategoryId] = useState(null)
    const { data: response, error: error } = useCommunityDashboardQuery()
    const { data: responseContributers, error: errorContributers } = useCommunityContributersQuery({
        category_id: categoryId
    })
    const { data: academicResponseData, error: academicResponseDataError } = useAcademicSummaryQuery()
    const { data: academicProgramResponseData, error: academicProgramResponseDataError } = useAcademicProgramsQuery()
    const academic = academicResponseData?.data;
    const userActivity = academicProgramResponseData;
    const community = response?.data;
     const academicProgram = academicProgramResponseData?.data
   
    


    const contributers = responseContributers?.data;
    const handleCategoryChange = useCallback((e) => {
        setCategoryId(e.target.value)
    }, [])
    return (
        <div className='bg-white rounded-sm p-2'>
            <AcademicOverview academic={academic} />
            <div className="flex my-[100px]">
                <div className='w-[30%] h-[350px]'>
                    <AcademicUserTable academic={academic} />
                </div>
                <div className='w-[50%]'>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'area',
                            },
                            title: {
                                text: 'Monthly Unique Users',
                            },
                            xAxis: {
                                // categories: ['October 2023', 'November 2023', 'December 2023', 'January 2024', 'February 2024', 'March 2024', 'April 2024', 'May 2024', 'June 2024', 'July 2024', 'August 2024', 'September 2024'], 
                                categories: academic?.user_accessed.slice().reverse().map((data) => data?.activity_month),
                            },
                            yAxis: {
                                title: {
                                    text: 'Values',
                                },
                            },
                            series: [{
                                name: 'Users',
                                data: academic?.user_accessed.slice().reverse().map((data) => data?.user_count) // Add your actual data here
                            }],
                            plotOptions: {
                                line: {
                                    dataLabels: {
                                        enabled: true
                                    },
                                    enableMouseTracking: true
                                }
                            }
                        }}
                    />

                    {/* <CategoryWisePostsGraph community={community} /> */}
                </div>
                <div className='w-[50%]'>
                    {/* <CategoryWisePostsTable community={community} /> */}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'pie',
                            },
                            title: {
                                text: 'September 2024 Monthly Usages',
                            },
                            series: [{
                                name: 'Users',
                                colorByPoint: true,
                                data:academic?.current_month_activity.map(item => ({
                                    name: item.user_type??'Learner',
                                    y: parseInt(item.user_count, 10) || 0, // Ensure the value is a number
                                })),
                            }],
                                
                                 
                            plotOptions: {
                                pie: {
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.name}: {point.percentage:.1f} %'
                                    }
                                }
                            }
                        }}
                    />

                </div>
            </div>

            <div className="flex my-[100px]">
                <div className='w-[50%] '>
                    {/* <TrendingPosts community={community} /> */}
                    <UserEngagement academicProgram={academicProgram} />
                </div>
                <div className='w-[40%]'>
                    {/* <TrendingReels community={community} /> */}
                    <AcademicProgramTable academicProgram={academicProgram} />
                </div>
            </div>

            <div className="flex my-[100px]">
                <div className='w-[50%]'>
                    <UserActivityTable userActivity={userActivity} />

                </div>

            </div>
        </div>
    )
}

export default AcademicDashboard