import React from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import '../../pages/Table.css'
const AcademicUserTable = (props) => {
    const { academic } = props;

    return (

        <div className="tableWrapper">
            <table className="table-auto border-collapse border border-gray-400 fixed_header">
                <thead>
                    <tr className="">
                        <th className="border p-2 text-bold">User Type</th>
                        <th className="border p-2 text-bold">All Count</th>
                    </tr>

                </thead>
                <tbody className="trhover">
                    {academic?.user_type_count?.map((data, index) => (
                        <tr key={index}>
                            <td className="border p-2">{data?.user_type??'Learner'}</td>
                            <td className="border p-2">{data?.user_count??''}</td>

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>


    )


}

export default AcademicUserTable 