import React from 'react';
import { formatNumberWithCommas } from '../../utils/utils';

const AcademicOverview = (props) => {
    const { academic } = props;

    const getUserCount = (role) => {
        return academic?.user_role_count?.find((data) => data.role === role)?.user_count ?? 0;
    }
    const totalUserCount = academic?.user_role_count?.reduce((acc, item) => acc + item.user_count, 0) ?? 0;

    return (
        <div className="grid grid-cols-7 gap-4 mb-6">
             <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(totalUserCount)}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Active User</p>
            </div>
            {/* <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{getUserCount('Admin')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Admin</p>
            </div> */}
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(getUserCount('Learner'))}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Learners</p>
            </div>
            {/* <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{getUserCount('Lead')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Leads</p>
            </div> */}
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(getUserCount('Alumni'))}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Alumni</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(getUserCount('Presenter'))}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Presenters</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(getUserCount('Staff'))}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Staff</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(academic?.departments??'')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Departments</p>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{formatNumberWithCommas(academic?.program_count??'')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Programs</p>
            </div>
        </div>
    );
}

export default AcademicOverview;
