// /src/pages/CircuitAnimation.jsx
import React from 'react';
import { motion } from 'framer-motion';

const CircuitAnimation = () => {
  return (
    <svg
      className="absolute inset-0 w-full h-full pointer-events-none"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      {[...Array(20)].map((_, i) => (
        <motion.path
          key={i}
          d={`M${Math.random() * 100} ${Math.random() * 100} Q ${Math.random() * 100} ${Math.random() * 100}, ${Math.random() * 100} ${Math.random() * 100}`}
          stroke={`rgb(${Math.random() * 255},${Math.random() * 255},255)`}
          strokeWidth="0.5"
          fill="none"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 0.5 }}
          transition={{ duration: Math.random() * 2 + 1, delay: Math.random(), repeat: Infinity, repeatType: "reverse" }}
        />
      ))}
    </svg>
  );
};

export default CircuitAnimation;
