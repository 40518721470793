import React from 'react';

const EmployabilityOverview = (props) => {
    const { employabilityOverview } = props;

    
    return (
        <div className="grid grid-cols-5 gap-4 mb-6">
             <div className="bg-gray-200 p-4 rounded-md text-center">
             <p className="font-semibold">Total Opportunities</p>
             <h1 className="text-2xl text-blue-500">{ employabilityOverview?.opportunities || 0}</h1>
            </div>
            {/* <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{getUserCount('Admin')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Admin</p>
            </div> */}
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Vacancies</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.num_of_vacancy || 0}</h1>
            </div>
            {/* <div className="bg-gray-200 p-4 rounded-md text-center">
                <h2 className="text-2xl">{getUserCount('Lead')}</h2>
                <p className="text-[#4f8ae8] text-xl font-bold">Leads</p>
            </div> */}
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Applications</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.applied || 0}</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Under Process</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.under_process || 0}</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Student Placed</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.placed || 0}</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Applications Rejected</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.rejected || 0}</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Placement Percentage</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.placement_per || 0}%</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Published Jobs</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.opportunities || 0}</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Placement Percentage</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.placement_per || 0}%</h1>
            </div>
            <div className="bg-gray-200 p-4 rounded-md text-center">
            <p className="font-semibold">Total Unpublished Jobs</p>
            <h1 className="text-2xl text-blue-500">{ employabilityOverview?.opportunities || 0}</h1>
            </div>
        </div>
    );
}

export default EmployabilityOverview;



