import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

const UserEngagement = ({ academicProgram }) => {
    // Ensure academicProgram and activity_table are defined
    const activityTable = academicProgram?.activity_table;

    // If activity_table is undefined, return null or a fallback UI
    if (!activityTable) {
        return <div>No activity data available</div>;
    }

    // Extract categories (activity types) and user counts for each role
    const activityCategories = Object.keys(activityTable); // Ensure activityTable exists
    const roles = ["Admin", "Alumni", "Lead", "Learner", "Presenter"];

    // Prepare series data for each role
    const seriesData = roles.map((role) => ({
        name: role,
        data: activityCategories.map((activity) =>
            activityTable[activity].find(item => item.role === role)?.user_count || 0
        )
    }));

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    type: 'bar',
                    inverted: true,
                },
                credits: { enabled: false },
                title: {
                    text: 'User Enagegment',
                },
                xAxis: {
                    categories: activityCategories, // Activities as categories (e.g., "Learning", "Event", etc.)
                    title: {
                        text: "Activities"
                    }
                },
                yAxis: {
                    title: {
                        text: "User Count",
                    },
                },
                series: seriesData, // Data for each role
            }}
        />
    );
};

export default UserEngagement;
