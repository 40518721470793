import React from 'react';
import '../../pages/Table.css';

const AcademicProgramTable = (props) => {
    const { academicProgram } = props;

    return (
        <div className="tableWrapper" style={{ width: '600px', height: '350px', position: 'relative', overflow: 'hidden' }}>
            <table className="table-auto border-collapse border border-gray-400 w-full fixed_header">
                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <tr>
                        <th className="border p-2 text-bold" style={{ width: '25%' }}>Joining Date</th>
                        <th className="border p-2 text-bold" style={{ width: '50%' }}>Program Name</th>
                        <th className="border p-2 text-bold"  style={{ width: '12.5%' }}>Faculty</th>
                        <th className="border p-2 text-bold" style={{ width: '12.5%' }}>Student</th>
                    </tr>
                </thead>
                <tbody className="trhover" style={{ display: 'block', overflowY: 'scroll', height: 'calc(350px - 41px)' }}>
                    {academicProgram?.program_assign?.map((data, index) => (
                        <tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
                            <td className="border p-2" style={{ width: '25%' }}>{data?.assign_date ?? 'N/A'}</td>
                            <td className="border p-2" style={{ width: '50%' }}>{data?.program_name ?? 'N/A'}</td>
                            <td className="border p-2" style={{ width: '12.5%' }}>{data?.user_count?.Faculty ?? 0}</td>
                            <td className="border p-2" style={{ width: '12.5%' }}>{data?.user_count?.Learner ?? 0}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AcademicProgramTable;