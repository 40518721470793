import React from 'react';
import '../../pages/Table.css';

const UserActivityTable = (props) => {
    const { userActivity } = props;
   
    if (!userActivity || !userActivity?.data?.activity_table) {
        return <div>No data available</div>;
    }

    return (
        <div className="tableWrapper" style={{ width: '600px', height: '350px', overflowY: 'auto', overflowX: 'auto' }}>
            <table className="table-auto border-collapse border border-gray-400 w-full">
                <thead>
                    <tr className="bg-blue-500">
                        <th className="border p-2 bg-blue-400 text-white ">Activity</th>
                        <th className="border p-2 text-bold  bg-blue-400 text-white">Admin</th>
                        <th className="border p-2 text-bold  bg-blue-400 text-white">Alumni</th>
                        <th className="border p-2 text-bold  bg-blue-400 text-white">Lead</th>
                        <th className="border p-2 text-bold  bg-blue-400 text-white">Learner</th>
                        <th className="border p-2 text-bold  bg-blue-400 text-white">Presenter</th>
                    </tr>
                </thead>
                <tbody className="trhover">
                    {Object?.keys(userActivity?.data?.activity_table).map((activity, index) => (
                        <tr key={activity}> {/* Adding a unique key based on the activity name */}
                            <td className="border p-2">{activity}</td>
                            {userActivity?.data?.activity_table[activity].map((roleData, roleIndex) => (
                                <td key={`${activity??''}-${roleIndex??''}`} className="border p-2">
                                    {roleData?.user_count??''}
                                </td> // Adding a unique key based on activity and roleIndex
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserActivityTable;
