import React, { useState } from 'react';
import '../common/common.css';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { response } from '../mapdata/employabilityData';
import { formatNumberWithCommas } from '../utils/utils';
import employbility_banner from '../assets/employbility_banner.png';



import { useGetMecBDEmpQuery, useGetMecBDDashboardQuery, useEmployabilityDetailsQuery } from '../redux/slice/apiSlice';
import EmployabilityOverview from './EmployabilityOverview';
const Employability = () => {
    const [yearChange, setYearChange] = useState('2023-24');
    const [year2023, setYear2023] = useState('2023-24');
    const [year2022, setYear2022] = useState('');
    const [year2021, setYear2021] = useState('');

    const { data: emloyabilityDetails, isLoading: emloyabilityDetailsLoading, error: emloyabilityDetailsError } = useEmployabilityDetailsQuery();

    const [enrolmentsOverall, setEnrolmentsOverall] = useState(true);
    const [enrolmentsMinistry, setEnrolmentsMinistry] = useState(false);
    const [enrolmentsDirect, setEnrolmentsDirect] = useState(false);

    const [leadyear2023, setLeadyear2023] = useState('leadyear2023');
    const [leadyear2022, setLeadyear2022] = useState('');
    const [leadyear2021, setLeadyear2021] = useState('');

    const [leadconversion2023, setLeadconversion2023] = useState('2023-24');
    const [leadconversion2022, setLeadconversion2022] = useState('');
    const [leadconversion2021, setLeadconversion2021] = useState('');
    const [employmentTargetValue, setEmploymentTargetValue] = useState('overall')
    const [yearMecBDEmpResponse, setMecBDEmpResponse] = useState(null)
    const [employmentMixYear, setEmploymentMixYear] = useState(null)
    const [corporatesHiringYear, setCorporatesHiringYear] = useState(null)
    const { data: mecBDEmpResponse, error: MecBDEmpError } = useGetMecBDEmpQuery({
        academic_year: yearMecBDEmpResponse
    });
    const { data: mecBDDashboardResponse, error: MecBDDashboardError } = useGetMecBDDashboardQuery({
        emp_provider: employmentTargetValue
    });
    const { data: employmentMixResponse, error: employmentMixError } = useGetMecBDDashboardQuery({
        academic_year: employmentMixYear
    });
    const { data: corporatesHiringResponse, error: corporatesHiringError } = useGetMecBDDashboardQuery({
        academic_year: corporatesHiringYear,
        is_company: 1
    });
    const employabilityOverview = emloyabilityDetails?.data?.job_couts|| []


    const handleEmploymentTargetValue = (e) => {
        setEmploymentTargetValue(e.target.value)
    }

    const YearChange = (e) => {
        const selectedYear = e.target.value;
        setYearChange(selectedYear);

        if (selectedYear === "2023-24") {
            setYear2023(selectedYear);
            setYear2021('');
            setYear2022('');
        } else if (selectedYear === "2022-23") {
            setYear2022(selectedYear);
            setYear2023('');
            setYear2021('');
        } else if (selectedYear === "2021-22") {
            setYear2021(selectedYear);
            setYear2022('');
            setYear2023('');
        }
    };

    const LearnSourceMixYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadyear2023(selectedYear === "2023-24" ? selectedYear : '');
        setLeadyear2022(selectedYear === "2022-23" ? selectedYear : '');
        setLeadyear2021(selectedYear === "2021-22" ? selectedYear : '');
    };

    const leadConversionYearChange = (e) => {
        const selectedYear = e.target.value;
        setLeadconversion2023(selectedYear === "2023-24" ? selectedYear : '');
        setLeadconversion2022(selectedYear === "2022-23" ? selectedYear : '');
        setLeadconversion2021(selectedYear === "2021-22" ? selectedYear : '');
    };

    const EnrolmentsButtonClick = (tab) => {
        if (tab === "overall") {
            setEnrolmentsOverall(true);
            setEnrolmentsMinistry(false);
            setEnrolmentsDirect(false);
        } else if (tab === "ministry") {
            setEnrolmentsMinistry(true);
            setEnrolmentsOverall(false);
            setEnrolmentsDirect(false);
        } else {
            setEnrolmentsDirect(true);
            setEnrolmentsOverall(false);
            setEnrolmentsMinistry(false);
        }
    };

    const portfolioCompletionData = emloyabilityDetails?.data?.portfolio_completion_per || [];

    const donutChartOptions = {
        chart: {
            type: 'pie',
        },
        title: {
            text: 'Portfolio Completion Status',
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
            pie: {
                innerSize: '50%', // This creates the donut shape
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true,
            },
        },
        series: [{
            name: 'Completion Percentage',
            data: portfolioCompletionData.map(item => ({
                name: item.name,
                y: item.y,
            })),
        }],
    };



    // ... rest of your render function logic using the state variables ...

    return (
        <div className='employabilityWrapper'>
            <section>
                <div className='infoWrapper'>
                    <div className='pageTitle'>
                        <h1>Employability</h1>
                    </div>


                    <div className='pageActions'>
                        <label>Year</label>
                        <select onChange={(e) => setMecBDEmpResponse(e.target.value)} >
                            {mecBDEmpResponse?.data?.academic_year.map(data => <option value={data}>{data}</option>)}
                        </select>
                    </div>
                </div>
            </section>
             <section className=' bg-white rounded-lg p-6'>
             <EmployabilityOverview employabilityOverview={employabilityOverview} />
             </section>
            

         
            

           <section className='flex bg-white' >
           <div className="bg-white shadow-md rounded-lg p-6 ">
                <div className="text-lg font-semibold">Portfolio Completion Analytics</div>
                <div className="text-sm text-gray-600 mb-4">
                    Here are the analytics that detail the completion status of the Portfolio.
                </div>
                <div className="flex items-center">
                    <div className="text-lg font-bold">
                        {emloyabilityDetails?.data?.total_portfolio_created || 0} out of {emloyabilityDetails?.data?.portfolio_not_created + emloyabilityDetails?.data?.total_portfolio_created || 0} users have taken the initiative to create their portfolios.
                    </div>
                </div>

                {/* Portfolio table */}
                <div className="mt-4">
                    <table className="min-w-full bg-gray-100">
                        <thead>
                            <tr>
                                <th className="py-2">Role</th>
                                <th className="py-2"><center>Profiles Created</center></th>
                                <th className="py-2"><center>Total Users in Role</center></th>
                            </tr>
                        </thead>
                        <tbody>
                            {emloyabilityDetails?.data?.portfolio_table?.map((roleData, index) => (
                                <tr key={index}>
                                    <td className="py-2">{roleData.role}</td>
                                    <td className="py-2">{roleData.profile_created}</td>
                                    <td className="py-2">{roleData.total_user_role}</td>
                                </tr>
                            )) || (
                                    <tr>
                                        <td className="py-2" colSpan="3">No data available</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    
                </div>
               
            </div>

        <div className=""></div>
        <HighchartsReact highcharts={Highcharts} options={donutChartOptions} />

           </section>





            <section className='chartWrapper'>

                <div className='middle'>
                    <div className='chartheading'>
                        <h3>Employment Mix</h3>
                        <select onChange={(e) => setEmploymentMixYear(e.target.value)} >
                            {employmentMixResponse?.data?.academic_year.map((data) => <option value={data}>{data}</option>)}

                        </select>
                    </div>

                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'pie',

                            },

                            accessibility: {
                                point: {
                                    valueSuffix: '%'
                                }
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}%</b>'
                            },
                            title: {
                                text: ''
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                                // labelFormatter: function() {
                                //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                // }
                            },
                            plotOptions: {
                                pie: {
                                    shadow: false,
                                    center: ['50%', '50%'],

                                },
                                series: {
                                    allowPointSelect: true,
                                    showInLegend: true,
                                    dataLabels: {
                                        enabled: false,
                                        format: '{point.name}',

                                    },

                                }
                            },


                            series: [{
                                name: 'Functional Domain',
                                colorByPoint: true,
                                data: employmentMixResponse?.data?.domain_target.map((data) => {
                                    return {
                                        "name": data?.functional_domain,
                                        "y": Number(data?.target),

                                    }
                                }),
                            }],
                            // series: response[yearChange].employabilityMixyear2023pie,



                        }}
                    />


                 
                </div>
                <div className='right'>
                    <div className='chartheading'>
                        <h3>Corporates Hiring</h3>
                        <select onChange={(e) => this.leadConversionYearChange(e)} >
                            <option value={"2023-24"}>2023-24 (Base Year)</option>
                            <option value={"2022-23"}>2022-23</option>
                            <option value={"2021-22"}>2021-22</option>
                        </select>
                    </div>
                    {emloyabilityDetails &&
                      <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                          chart: {
                              type: 'column',

                          },
                          credits: { enabled: false },
                          title: {
                              text: 'Corporates Hiring'
                          },
                          xAxis: {
                              type: 'category',
                              categories: emloyabilityDetails?.data?.company_target.map((data) => data?.company),
                           
                               
                              max: 3,
                              tickLength: 0,
                              scrollbar: {
                                  enabled: true,
                                  showFull: false
                              },
                          },
                          yAxis: {
                              title: {
                                  text: "Corporates Hiring"
                              },
                          },
                          plotOptions: {
                              column: {
                                  stacking: 'normal',
                                  dataLabels: {
                                      enabled: false
                                  }
                              }
                          },

                          series: [
                              {
                                  name: 'Full Time',
                                  color: "#3142A3",
                                  data: emloyabilityDetails?.data?.company_target.map(item => ({
                                      name: item?.company,
                                      y: parseInt(item?.full_time, 10) || 0, // Ensure the value is a number
                                  })),
                              },
                              {
                                  name: 'Internship',
                                  color: "#87A2FF",
                                  data: emloyabilityDetails?.data?.company_target.map(item => ({
                                      name: item?.company,
                                      y: parseInt(item?.internship, 10) || 0, // Ensure the value is a number
                                  })),
                              },
                              // {
                              //     name: 'Total User Attempted',
                              //     color: "#59BFEC",
                              //     data: getEventsEnrollment?.data?.top_events.map(item => ({
                              //         name: item.name,
                              //         y: parseInt(item.total_user_attempted, 10) || 0, // Ensure the value is a number
                              //     })),
                              // }
                          ],
                      }}
                  />
                    }

</div>
                <div className='right singleChart'>
                    <div className='chartheading'>
                        <h3>Employment Targets</h3>
                        <select onChange={(e) => setEmploymentMixYear(e.target.value)} >
                            {employmentMixResponse?.data?.academic_year.map((data) => <option value={data}>{data}</option>)}

                        </select>
                    </div>

                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'pie',

                            },

                            accessibility: {
                                point: {
                                    valueSuffix: '%'
                                }
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}%</b>'
                            },
                            title: {
                                text: ''
                            },
                            legend: {
                                enabled: true,
                                floating: false,
                                borderWidth: 0,
                                align: 'center',
                                layout: 'horizontal',
                                verticalAlign: 'bottom',
                                padding: 3,
                                // labelFormatter: function() {
                                //   return '<span style="color:' + this.color + '">' + this.name + ': </span>(<b>' + this.y + '%)<br/>';
                                // }
                            },
                            plotOptions: {
                                pie: {
                                    shadow: false,
                                    center: ['50%', '50%'],
                                    innerSize: '50%',

                                },
                                series: {
                                    allowPointSelect: true,
                                    showInLegend: true,
                                    dataLabels: {
                                        enabled: false,
                                        format: '{point.name}',

                                    },

                                }
                            },


                            series: [{
                                name: 'Company Targets',
                                colorByPoint: true,
                                data: emloyabilityDetails?.data?.company_target.map((data) => {
                                    return {
                                        "name": data?.company,
                                        "y": Number(data?.full_time),

                                    }
                                }),
                            }],
                            // series: response[yearChange].employabilityMixyear2023pie,



                        }}
                    />


                 
                </div>


               
                
            </section>
           


            <section className='chartWrapper'>
                <div className='left singleChart'>
                    <div className='chartheading'>
                        <h3>Programme Wise Employability</h3>
                    </div>
                    {/* {JSON.stringify(response.year2023.programWiseEnrollment)}              */}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                            credits: { enabled: false },
                            chart: {
                                type: 'column',
                                scrollablePlotArea: {
                                    maxWidth: response[yearChange]?.programWiseEnrollment?.category?.length, // Set the minimum width for scrollable plot area
                                }
                            },
                            title: {
                                text: ''
                            },
                            xAxis: {
                                type: 'category',
                                max: 9,
                                tickLength: 0,
                                scrollbar: {
                                    enabled: true,
                                    showFull: false
                                },
                                crosshair: true,
                                categories: response[yearChange]?.programWiseEnrollment?.category,
                                labels: {
                                    autoRotation: [-25, 0],
                                    rotation: 0,
                                    style: {
                                        fontSize: '10px',
                                    }
                                },
                            },


                            yAxis: {
                                title: {
                                    text: 'Programme Wise Enrolments(10 thousands)'
                                },
                                tickLength: 0,
                                scrollbar: {
                                    enabled: false,
                                    showFull: false
                                },

                            },

                            legend: {
                                enabled: true
                            },

                            plotOptions: {
                                column: {
                                    stacking: 'normal',
                                    dataLabels: {
                                        enabled: false
                                    }
                                }
                            },
                            series: response[yearChange]?.programWiseEnrollment?.data,

                        }}
                    />
                </div>
            </section>

            








        </div>
    )
};

export default Employability;