import { motion } from 'framer-motion'

const LineChart = ({ data }) => (
  <div className="w-1/2 h-1/2 flex items-end justify-center">
    <svg className="w-full h-full">
      <motion.path
        d={`M ${data.map((value, index) => `${(index / (data.length - 1)) * 100},${100 - value}`).join(' L ')}`}
        fill="none"
        stroke="rgba(59, 130, 246, 0.5)"
        strokeWidth="4"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={{ duration: 2, ease: "easeInOut" }}
      />
    </svg>
  </div>
)

export default LineChart
