// /src/pages/ChartAnimation.jsx
import React, { useEffect, useState } from 'react';
import BarChart from './BarCharts';
import LineChart from './LineCharts';
import PieChart from './PieCharts';
import ScatterPlot from './scattersPlots';

const ChartAnimation = () => {
  const [chartData, setChartData] = useState({
    barChart: Array(10).fill(0),
    lineChart: Array(10).fill(0),
    pieChart: Array(5).fill(0),
    scatterPlot: Array(20).fill({ x: 0, y: 0 }),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setChartData({
        barChart: chartData.barChart.map(() => Math.random() * 100),
        lineChart: chartData.lineChart.map(() => Math.random() * 100),
        pieChart: chartData.pieChart.map(() => Math.random() * 100),
        scatterPlot: chartData.scatterPlot.map(() => ({
          x: Math.random() * 100,
          y: Math.random() * 100,
        })),
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [chartData]);

  return (
    <div className="absolute inset-0 flex flex-wrap items-center justify-center pointer-events-none">
      <BarChart data={chartData.barChart} />
      <LineChart data={chartData.lineChart} />
      <PieChart data={chartData.pieChart} />
      <ScatterPlot data={chartData.scatterPlot} />
    </div>
  );
};

export default ChartAnimation;
