import { motion } from 'framer-motion'

const PieChart = ({ data }) => (
  <div className="w-1/2 h-1/2 flex items-center justify-center">
    <svg className="w-full h-full" viewBox="0 0 100 100">
      {data.map((value, index) => {
        const total = data.reduce((sum, v) => sum + v, 0)
        const startAngle = data.slice(0, index).reduce((sum, v) => sum + (v / total) * 360, 0)
        const endAngle = startAngle + (value / total) * 360
        const x1 = 50 + 40 * Math.cos((startAngle * Math.PI) / 180)
        const y1 = 50 + 40 * Math.sin((startAngle * Math.PI) / 180)
        const x2 = 50 + 40 * Math.cos((endAngle * Math.PI) / 180)
        const y2 = 50 + 40 * Math.sin((endAngle * Math.PI) / 180)
        const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1

        return (
          <motion.path
            key={index}
            d={`M 50 50 L ${x1} ${y1} A 40 40 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
            fill={`hsl(${index * 60}, 70%, 50%)`}
            opacity={0.5}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          />
        )
      })}
    </svg>
  </div>
)

export default PieChart
