import { motion } from 'framer-motion'

const BarChart = ({ data }) => (
  <div className="w-1/2 h-1/2 flex items-end justify-center">
    {data.map((height, index) => (
      <motion.div
        key={index}
        className="w-4 mx-1 bg-blue-400 opacity-50"
        initial={{ height: 0 }}
        animate={{ height: `${height}%` }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      />
    ))}
  </div>
)

export default BarChart
