// // src/slice/pageTitleSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// const pageTitleSlice = createSlice({
//   name: 'pageTitle',
//   initialState: {
//     title: '',
//   },
//   reducers: {
//     setTitle(state, action) {
//       state.title = action.payload;
//     },
//   },
// });

// export const { setTitle } = pageTitleSlice.actions;
// export default pageTitleSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: localStorage.getItem('pageTitle') || 'Dashboard',
};

const pageTitleSlice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setTitle(state, action) {
      state.title = action.payload;
      localStorage.setItem('pageTitle', action.payload);
    },
  },
});

export const { setTitle } = pageTitleSlice.actions;
export default pageTitleSlice.reducer;