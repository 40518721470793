import React from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';

const CategoryWisePostsGraph = (props) => {
    const { community } = props;


    return (
        <HighchartsReact
            highcharts={Highcharts}

            options={{
                chart: {
                    type: 'bar',
                    inverted: true,

                },
                credits: { enabled: false },
                title: {
                    text: 'Community Contribution'
                },
                xAxis: {
                    categories: community?.category_post.slice(5)?.map((item) => item?.title)

                },
                yAxis: {
                    title: {
                        text: "Post Count"
                    },
                },

                series: [{
                    name: 'Posts',
                    colorByPoint: true,
                    data: community?.category_post.slice(5)?.map(item => ({
                        name: item.title,
                        y: parseInt(item.post_count, 10) || 0, // Ensure the value is a number
                    })),
                }],
            }}
        />
    )
}

export default CategoryWisePostsGraph