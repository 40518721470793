import { motion } from 'framer-motion'

const ScatterPlot = ({ data }) => (
  <div className="w-1/2 h-1/2 flex items-center justify-center">
    <svg className="w-full h-full" viewBox="0 0 100 100">
      {data.map((point, index) => (
        <motion.circle
          key={index}
          cx={point.x}
          cy={point.y}
          r="2"
          fill="rgba(59, 130, 246, 0.5)"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        />
      ))}
    </svg>
  </div>
)

export default ScatterPlot
