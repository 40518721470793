import React from 'react'
import { useAppSelector } from '../redux/store'

export default function Header() {
  const pageTitle = useAppSelector((state) => state.pageTitle.title)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.href = '/login'
  }

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  return (
    <header className="bg-white shadow">
      <div className="flex justify-between w-full px-4 py-2">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold">{pageTitle || "Dashboard"}</h1>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleLogout}
            className="p-2 text-gray-600 hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full"
            aria-label="Logout"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
          </button>
          <button
            className="p-2 text-gray-600 hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full"
            aria-label="Notifications"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button>
          <div className="flex items-center space-x-2">
            <img
              src={user.profile_image || '/placeholder.svg?height=32&width=32'}
              alt="User profile"
              className="h-8 w-8 rounded-full object-cover"
            />
            <div>
              <p className="text-sm font-medium text-gray-900">Admin</p>
              <p className="text-xs text-gray-500">MEC Admin</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}